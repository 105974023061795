body, html {
  height: 100%;
  background-color: black;
}

.bg {
  background-image: url('./assets/bg2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  min-height: 100vh;
}

.vh {
  height: 100vh;
}

.link:hover {
  text-decoration: none;
}

.txt {
  font-size: 18px !important;
}

.app-card {
  height: 80px;
  width: 210px;
  margin: 10px;
  /* font-weight: bold; */
  letter-spacing: 1px;
  background-color: #2f2f2f !important;
  border-radius: 6px;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.app-card:hover {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.24));
}

.card-img {
  max-width: 50px;
  margin-right: 15px;
}

.main-container {
  height: 100vh;
  width: 100vw;
}